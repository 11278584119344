import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Modal,
  PaginationProps,
  Table,
} from "semantic-ui-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/ext-searchbox.js";
import moment from "moment";
import {
  DashboardHistoryLogsType,
  DashboardHistoryType,
  fetchDashboardHistoryLogs,
  updateDashboardHistoryAndRollback,
} from "../../../BytebeamClient";
import LoadingAnimation from "../../common/Loader";
import { ActionTableRow } from "../Actions/LiveAction/LiveActionsList";
import ThemeSchema from "../../../theme/schema";
import { useUser } from "../../../context/User.context";
import { TableContainer } from "./Panel/util";
import { beamtoast } from "../../common/CustomToast";
import {
  StyledInput,
  StyledLabel,
} from "../Settings/actiontypes/ActionTypesModal";
import ConfirmationModal from "../common/ConfirmationModal";
import ConfirmationModalMessageNonDelete from "../common/ConfirmationModalMessageNonDelete";
import { StyledPagination } from "../../common/commonStyledComps";
import styled from "styled-components";

const StyledCheckbox = styled(Checkbox)`
  &&&& {
    & label {
      color: ${({ theme }) => theme.colors["text"]} !important;
    }
  }
`;

type RenameVersionModalProps = {
  readonly openVersionModal: boolean;
  readonly isRollbackOrVersionUpdateLoading: {
    readonly rename: boolean;
  };
  readonly logsLoading: boolean;
  readonly selectedVersion: DashboardHistoryLogsType | undefined;
  readonly setUpdatedVersionName: (updatedVersionName: string) => void;
  readonly updatedVersionName: string;
  readonly setOpenVersionModal: (openVersionModal: boolean) => void;
  readonly updateVersionNameOrRollback: (
    versionId: number,
    versionName: string,
    event: "version_edit" | "rollback"
  ) => void;
};

/*
 * Modal for renaming the dashboard history version name.
 */
const RenameVersionModal = ({
  isRollbackOrVersionUpdateLoading,
  logsLoading,
  selectedVersion,
  openVersionModal,
  updateVersionNameOrRollback,
  setUpdatedVersionName,
  updatedVersionName,
  setOpenVersionModal,
}: RenameVersionModalProps) => {
  return (
    <Modal
      trigger={
        <Button
          primary
          loading={isRollbackOrVersionUpdateLoading?.rename}
          disabled={logsLoading || !selectedVersion}
          onClick={() => setUpdatedVersionName(selectedVersion?.name ?? "")}
        >
          Rename Version
        </Button>
      }
      className="dark"
      onClose={() => setOpenVersionModal(false)}
      onOpen={() => setOpenVersionModal(true)}
      open={openVersionModal}
      size="tiny"
    >
      <Modal.Header>Rename Version</Modal.Header>
      <Modal.Content>
        <StyledInput labelPosition="left">
          <StyledLabel>Name</StyledLabel>
          <input
            autoFocus
            placeholder="Enter version name"
            value={updatedVersionName}
            onChange={(e) => setUpdatedVersionName(e.target.value)}
          />
        </StyledInput>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => setOpenVersionModal(false)}>
          Cancel
        </Button>
        <Button
          primary
          onClick={() => {
            if (updatedVersionName.length === 0) {
              beamtoast.error("Version name cannot be empty");
              return;
            }
            updateVersionNameOrRollback(
              selectedVersion?.id ?? 0,
              updatedVersionName,
              "version_edit"
            );
            setOpenVersionModal(false);
          }}
        >
          Rename
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

type DashboardHistoryModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: (isOpen: boolean) => void;
  readonly dashboardId: number;
  readonly isDashboardEditable: boolean;
  readonly refreshDashboard: () => void;
  readonly onCancel: () => void;
};

const DashboardHistoryModal = ({
  isOpen,
  setIsOpen,
  dashboardId,
  isDashboardEditable,
  refreshDashboard,
  onCancel,
}: DashboardHistoryModalProps) => {
  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const logsPerPageLimit = 10;

  const [showNamedVersionOnly, setShowNamedVersionOnly] = useState(false);
  const [logsLoading, setLogsLoading] = useState(true);
  const [dashboardHistoryLogs, setDashboardHistoryLogs] = useState<
    DashboardHistoryType | undefined
  >();
  const [selectedVersion, setSelectedVersion] = useState<
    DashboardHistoryLogsType | undefined
  >();
  const [activePage, setActivePage] = useState(1);

  const [openVersionModal, setOpenVersionModal] = useState(false);
  const [updatedVersionName, setUpdatedVersionName] = useState("");
  const [
    isRollbackOrVersionUpdateLoading,
    setIsRollbackOrVersionUpdateLoading,
  ] = useState({
    rollback: false,
    rename: false,
  });

  const fetchDashboardHistory = async (
    dashboardId: number,
    showNamedVersionOnly: boolean,
    activePage: number,
    logsPerPageLimit: number
  ) => {
    setLogsLoading(true);
    try {
      const res = await fetchDashboardHistoryLogs(
        dashboardId,
        showNamedVersionOnly,
        activePage,
        logsPerPageLimit
      );
      setDashboardHistoryLogs(res);
      setSelectedVersion(res?.logs[0] ?? "");
    } catch (error) {
      console.error(error);
    } finally {
      setLogsLoading(false);
    }
  };

  const updateVersionNameOrRollback = async (
    versionId: number,
    versionName: string,
    event: "version_edit" | "rollback"
  ) => {
    if (event === "version_edit") {
      setSelectedVersion(undefined);
      setIsRollbackOrVersionUpdateLoading({
        ...isRollbackOrVersionUpdateLoading,
        rename: true,
      });
    } else {
      setIsRollbackOrVersionUpdateLoading({
        ...isRollbackOrVersionUpdateLoading,
        rollback: true,
      });
    }
    try {
      setLogsLoading(true);
      const res = await updateDashboardHistoryAndRollback(
        dashboardId,
        versionId,
        versionName,
        event
      );
      if (res) {
        // Update the dashboard history logs table after updating the version name.
        if (event === "version_edit") {
          beamtoast.success("Version name updated successfully");
          fetchDashboardHistory(
            dashboardId,
            showNamedVersionOnly,
            activePage,
            logsPerPageLimit
          );
        } else {
          beamtoast.success("Dashboard rolled back successfully");
          setIsOpen(false);
          refreshDashboard();
        }
      }
    } catch (error) {
      console.error(error);
      beamtoast.error(
        event === "version_edit"
          ? "Failed to update version name"
          : "Failed to rollback"
      );
    } finally {
      setIsRollbackOrVersionUpdateLoading({
        rollback: false,
        rename: false,
      });
    }
  };

  const onPaginationChange = (event: MouseEvent, data: PaginationProps) => {
    const activePage = data.activePage as number;
    setLogsLoading(true);
    setActivePage(activePage);
    fetchDashboardHistory(
      dashboardId,
      showNamedVersionOnly,
      activePage,
      logsPerPageLimit
    );
  };

  useEffect(() => {
    if (isOpen) fetchDashboardHistory(dashboardId, showNamedVersionOnly, 1, 10);
    setActivePage(1);
  }, [isOpen, showNamedVersionOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      centered
      open={isOpen}
      size="fullscreen"
      onClose={onCancel}
      className="dark"
      style={{ width: "auto" }}
    >
      <Modal.Header>Dashboard History</Modal.Header>
      <Modal.Content>
        <Grid columns={2}>
          <Grid.Column width={7}>
            <TableContainer
              style={{ padding: "0px", height: "500px", overflowY: "scroll" }}
            >
              <div className="tableContentContainer" style={{ padding: "0px" }}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={3}>Version</Table.HeaderCell>
                      <Table.HeaderCell width={3}>Time</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {logsLoading ? (
                      <Table.Row>
                        <Table.Cell colSpan={2}>
                          <LoadingAnimation
                            fontSize="1.1rem"
                            loaderSize="2.2rem"
                            loaderBorderSize="0.3rem"
                            marginTopText="12px"
                            loaderContainerMargin="1rem"
                            loadingText="Loading Dashboard History..."
                          />
                        </Table.Cell>
                      </Table.Row>
                    ) : dashboardHistoryLogs &&
                      dashboardHistoryLogs.count > 0 ? (
                      dashboardHistoryLogs.logs.map((log) => (
                        <ActionTableRow
                          key={log.id}
                          className={
                            selectedVersion?.id === log.id ? "selected" : ""
                          }
                          onClick={() => setSelectedVersion(log)}
                        >
                          <Table.Cell>
                            <Table.Row>{log.user_email}</Table.Row>
                            <Table.Row>{`${log.name ? `(${log.name})` : "(unamed version)"}`}</Table.Row>
                          </Table.Cell>
                          <Table.Cell>
                            {moment(log.created_at).format(
                              "ddd, MMM Do YYYY, HH:mm:ss"
                            )}
                          </Table.Cell>
                        </ActionTableRow>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={2}>No versions found</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </div>
            </TableContainer>

            {!logsLoading && dashboardHistoryLogs?.count !== 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  marginTop: "1rem",
                }}
              >
                <StyledPagination
                  boundaryRange={0}
                  defaultActivePage={activePage}
                  ellipsisItem={null}
                  siblingRange={2}
                  totalPages={Math.ceil(
                    (dashboardHistoryLogs?.count ?? 0) / logsPerPageLimit
                  )}
                  onPageChange={onPaginationChange}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <StyledCheckbox
                label="Show named versions only"
                onChange={() => setShowNamedVersionOnly((prev) => !prev)}
                checked={showNamedVersionOnly}
              />

              {
                // If the user is the admin or owner of the dashboard, the Rename Version Modal will be displayed.
                isDashboardEditable && dashboardHistoryLogs?.count !== 0 && (
                  <RenameVersionModal
                    openVersionModal={openVersionModal}
                    isRollbackOrVersionUpdateLoading={
                      isRollbackOrVersionUpdateLoading
                    }
                    logsLoading={logsLoading}
                    selectedVersion={selectedVersion}
                    setUpdatedVersionName={setUpdatedVersionName}
                    updatedVersionName={updatedVersionName}
                    setOpenVersionModal={setOpenVersionModal}
                    updateVersionNameOrRollback={updateVersionNameOrRollback}
                  />
                )
              }
            </div>
          </Grid.Column>

          <Grid.Column
            width={9}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <AceEditor
              height="500px"
              width="100%"
              mode="json"
              readOnly
              theme={
                ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-theme"]
              }
              name="custom-json"
              fontSize={14}
              // Set the selected version JSON here, with proper formatting
              value={
                logsLoading
                  ? "Loading..."
                  : JSON.stringify(selectedVersion?.dashboard_json, null, 2)
              }
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: false,
                tabSize: 2,
              }}
              style={{
                marginBottom: "16px",
                borderRadius: "4px",
                border: `${
                  ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]
                }`,
                boxShadow: `${
                  ThemeSchema.data[theme ?? "dark"]?.colors[
                    "ace-editor-box-shadow"
                  ]
                }`,
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                width: "100%",
              }}
            >
              {
                // If the user is not the admin or owner of the dashboard, the Rollback button will be disabled.
                isDashboardEditable && dashboardHistoryLogs?.count !== 0 && (
                  <ConfirmationModal
                    prefixContent="Are you absolutely sure?"
                    expectedText="Yes"
                    onConfirm={() => {
                      updateVersionNameOrRollback(
                        selectedVersion?.id ?? 0,
                        selectedVersion?.name ?? "",
                        "rollback"
                      );
                    }}
                    trigger={
                      <Button
                        primary
                        disabled={
                          logsLoading ||
                          isRollbackOrVersionUpdateLoading.rollback
                        }
                        loading={isRollbackOrVersionUpdateLoading.rollback}
                      >
                        Rollback
                      </Button>
                    }
                    message={
                      <ConfirmationModalMessageNonDelete
                        name="Rollback the dashboard to this version"
                        expectedText={"Yes"}
                        type={""}
                        specialMessage={
                          "This will replace the current version."
                        }
                      />
                    }
                  />
                )
              }
              <Button secondary onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default DashboardHistoryModal;
