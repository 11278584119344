import React, { Component } from "react";
import { ActionButtons, ActionButton } from "./ActionButtons";
import { UpdateConfigModal } from "./ActionModals/UpdateConfigModal";
import { UpdateFirmwareModal } from "./ActionModals/UpdateFirmwareModal";
import { RemoteShellModal } from "./ActionModals/RemoteShellModal";
import DeviceProvisionModal from "../DeviceProvisionModal";
import { ActionConfirmationModal } from "./ActionModals/ActionConfirmationModal";
import { UpDownArrows } from "../../common/UpDownArrows.svg";
import {
  Icon,
  SemanticICONS,
  Accordion,
  Popup,
  Dropdown,
  Pagination,
  Checkbox,
  Container,
  Grid,
  MenuItem,
  SemanticWIDTHS,
} from "semantic-ui-react";
import {
  triggerDeviceAction,
  downloadCertificates,
  changeDeviceStatus,
  Device,
  fetchAllDashboards,
  fetchTableInfo,
  devicesPerPageOptions,
  fetchAllStreamsWithDetails,
  FetchStreamsAPIResponse,
  fetchAllDevicesWithCountV2,
  searchDevices,
  SearchDeviceResponse,
  searchDevicesFilters,
  fetchAllActionTypes,
} from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import Toggle from "../../../common/Toggle";
import {
  User,
  Permission,
  ActionType,
  validateWholeNumber,
  AtLeastOnePropRequired,
} from "../../../../util";
import {
  Column,
  capitalizeFirstLetter,
  DisplayIf,
  HARDWARE_TYPES,
  filterTableInfo,
  SearchPageInput,
} from "../../util";
import moment, { Moment } from "moment";
import DeviceCard, { StyledGridColumn } from "./Device";
import BulkMetadataOperationsModal from "./ActionModals/BulkMetadataOperationsModal";
import { SendFileModal } from "./ActionModals/SendFileModal";
import { ErrorMessage } from "../../../common/ErrorMessage";
import styled from "styled-components";
import { ThinDivider } from "../../Dashboards/Panel/util";
import StyledSVGIcon from "../../../common/StyledSVGIcon";
import { SendScriptModal } from "./ActionModals/SendScriptModal";
import LoadingAnimation from "../../../common/Loader";
import { StyledDevicePerPageWidget } from "../../../common/commonStyledComps";
import {
  beamtoast,
  handleConditionalDismissibleToast,
} from "../../../common/CustomToast";
import DevicesFilterUI from "./DevicesFilterUI/DevicesFilterUI";
import ActionButtonV3 from "../../Actions/ActionsV3/ActionButton";
import {
  ActionRequestBody,
  PhaseData,
  PhaseInfo,
  ScheduleData,
  neverTimestamp,
} from "../../Actions/NewAction/NewAction";

type DevicesProps = {
  user: User;
};

export type ModifiedPhaseInfo = Omit<PhaseInfo, "filter"> & {
  filter?: Record<string, string | string[]>;
};

export type UpdatedPhaseData = Omit<PhaseData, "info" | "id"> & {
  info: ModifiedPhaseInfo;
};

export type UpdatedScheduleData = Omit<ScheduleData, "phases"> & {
  phases: UpdatedPhaseData[];
};

type UpdatedActionRequestBody = Omit<ActionRequestBody, "schedule"> & {
  schedule: UpdatedScheduleData;
};

type DevicesState = {
  loading: boolean;
  devices: Device[];
  pageCount: number;
  /**  Map of selected devices with ids as keys and status as values */
  selectedDevices: Map<string | number, string>;
  selectedDeviceCount: number | string;
  updateFirmwareModalIsOpen: boolean;
  sendFileModalIsOpen: boolean;
  updateConfigModalIsOpen: boolean;
  updateGeofenceModalIsOpen: boolean;
  updateScriptModalIsOpen: boolean;
  bulkMetadataOperationsModalIsOpen: boolean;
  openConfirmationModal: boolean;
  remoteShellDeviceId: number;
  searchModeOn: boolean;
  allSelected: boolean;
  stateKeys: string[];
  metadataKeys: string[];
  errorOccurred: boolean;
  streamTypesMismatchError: boolean;
  filtersTypeMismatchToastId: string | null;
  activePage: number;
  actionButtonsDisabled: boolean;
  showFilterInputs: boolean;
  devicesFilterQuery: searchDevicesFilters;
  lastRefreshTime: Moment | null;
  isRefreshStale: boolean;
  eventType: ActionType;
  activeIndex: number;
  showInactiveDevice: boolean;
  showInactiveDeviceToggle: boolean;
  devicesPerPage: number;
  dashboards: any[];
  streamsList: Record<string, string[]>;
  detailedStreamsList: FetchStreamsAPIResponse;
  devicesCount: number;
  inputPageNumber: number;
  autoRetry: boolean;
  releaseNotes: string;
  endTimestamp: Date | number;
  actionTypes: ActionType[];
};

export function getStringifiedDeviceIDs(deviceIDArr) {
  return deviceIDArr?.map((deviceID) => "" + deviceID);
}

const DeviceCountLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 56px;
  background: #fff;
  color: #000;
  height: 28px;
  width: 52px;
  font-size: 12px !important;
  font-weight: 700 !important;
  border-radius: 0px 8px 8px 0px;
  border: ${(props) => props.theme.colors["container-border"]} !important;
  box-shadow: ${(props) => props.theme.colors["container-box_shadow"]};
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 90;
  &::before {
    content: "";
    position: absolute;
    left: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14px 14px 0;
    border-color: transparent #ffffff transparent transparent;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SelectDevicesPerPage = styled(Dropdown)`
  min-width: 65px !important;
  color: ${({ theme }) => theme.colors["text"]} !important;
  border: none !important;

  .visible.menu {
    margin: 0px !important;
  }
  .visible.menu::after {
    display: none !important;
  }

  &:hover {
    color: white !important;
  }
`;

export const StyledHeaderContainer = styled(Container)`
  padding: 20px;
  margin-top: 20px;
  color: #ffffff50;
  border-radius: 12px !important;
`;

export const StyledBodyContainer = styled(Container)`
  margin-bottom: 20px;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const ActionBarRight = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

export default class Devices extends Component<DevicesProps, DevicesState> {
  state = {
    loading: true,
    devices: [] as Device[],
    pageCount: 0,
    selectedDevices: new Map<string | number, string>(),
    selectedDeviceCount: 0,
    updateFirmwareModalIsOpen: false,
    sendFileModalIsOpen: false,
    updateConfigModalIsOpen: false,
    updateGeofenceModalIsOpen: false,
    updateScriptModalIsOpen: false,
    bulkMetadataOperationsModalIsOpen: false,
    openConfirmationModal: false,
    remoteShellDeviceId: -1,
    searchModeOn: false,
    allSelected: false,
    stateKeys: [],
    metadataKeys: [],
    errorOccurred: false,
    streamTypesMismatchError: false,
    filtersTypeMismatchToastId: null,
    activePage: 1,
    inputPageNumber: 0,
    actionButtonsDisabled: false,
    showFilterInputs: false,
    devicesFilterQuery: {} as searchDevicesFilters,
    lastRefreshTime: null,
    isRefreshStale: false,
    eventType: {
      type: "",
      icon: "" as SemanticICONS,
      payload_type: "none",
    },
    activeIndex: -1,
    // showInactiveDevice hides or shows the devices
    showInactiveDevice: false,
    // showInactiveDeviceToggle hides or shows the toggle and not the devices
    showInactiveDeviceToggle: true,
    devicesPerPage: 10,
    dashboards: [],
    streamsList: {},
    detailedStreamsList: {},
    devicesCount: 0,
    autoRetry: false,
    releaseNotes: "",
    endTimestamp: neverTimestamp,
    actionTypes: [] as ActionType[],
  };

  abortController = new AbortController();
  timeoutId;
  isComponentMounted: boolean = true;

  onSelectedDeviceCountChange = (count: number | string) => {
    this.setState({
      selectedDeviceCount: count,
    });
  };

  selectAll = () => {
    this.setState({
      allSelected: true,
    });
    this.onSelectedDeviceCountChange("All");
  };

  clearAll = () => {
    this.setState({
      allSelected: false,
      selectedDevices: new Map<string | number, string>(),
    });
    this.onSelectedDeviceCountChange(0);
  };

  /**
   * Updates the selected devices map with the provided device ID and status.
   *
   * @param id - The ID of the device.
   * @param status - The status of the device.
   */
  selectDevice = (id: number, status: string) => {
    this.setState(
      (prevState) => {
        const newMap = new Map(prevState.selectedDevices);
        newMap.set(id, status);
        return { selectedDevices: newMap };
      },
      () => {
        this.onSelectedDeviceCountChange(this.state.selectedDevices.size);
      }
    );
  };

  clearDevice = (id: number) => {
    this.setState(
      (prevState) => {
        const newMap = new Map(prevState.selectedDevices);
        newMap.delete(id);
        return { selectedDevices: newMap, allSelected: false };
      },
      () => {
        this.onSelectedDeviceCountChange(this.state.selectedDevices.size);
      }
    );
  };

  setShowFilterInputs = () => {
    this.setState((prevState) => ({
      showFilterInputs: !prevState.showFilterInputs,
    }));
  };

  setActionButtonsDisabled = (value) => {
    this.setState({
      actionButtonsDisabled: value,
    });
  };

  checkIfSelectedDevicesAreActive = () => {
    if (this.state.allSelected) return true;

    // Convert the Map values to an array
    const selectedDevices = Array.from(this.state.selectedDevices.values());

    // Check if any selected device has the status 'active'
    return selectedDevices.some((status) => status === "active");
  };

  createScheduleObject = (actionType: string): UpdatedScheduleData => {
    const {
      autoRetry,
      endTimestamp,
      releaseNotes,
      allSelected,
      searchModeOn,
      devicesFilterQuery,
      selectedDevices,
    } = this.state;

    const phaseInfo: ModifiedPhaseInfo = {
      type: allSelected ? "filter-fraction" : "fixed-list",
      ...(allSelected
        ? {
            filter: searchModeOn ? devicesFilterQuery.metaDatafilters : {},
            fraction: 100,
          }
        : {
            device_ids: getStringifiedDeviceIDs([
              // Convert the Map keys to an array of selected device IDs
              ...Array.from(selectedDevices.keys()),
            ]),
          }),
    };

    const phaseData: UpdatedPhaseData = {
      name: "Phase I",
      trigger_on: {
        timestamp: new Date().getTime(),
      },
      info: phaseInfo,
    };

    const scheduleObject: UpdatedScheduleData = {
      phases: [phaseData],
      end_timestamp:
        endTimestamp === neverTimestamp
          ? neverTimestamp
          : new Date().getTime() + endTimestamp,
      retry_on_failure_until: 0,
      release_notes: releaseNotes,
    };
    scheduleObject.retry_on_failure_until = autoRetry
      ? scheduleObject.end_timestamp
      : 0;

    return scheduleObject;
  };

  triggerAction = async (
    actionType: string,
    params: Record<string, any> | string = {}
  ) => {
    if (!this.checkIfSelectedDevicesAreActive()) {
      beamtoast.info(`Can not trigger actions for inactive devices!`);
      return;
    }

    const requestBody: UpdatedActionRequestBody = {
      action: actionType,
      params,
      schedule: this.createScheduleObject(actionType),
      search_type: "default",
    };

    this.setActionButtonsDisabled(true);
    try {
      await triggerDeviceAction(requestBody);
      beamtoast.success(`${actionType} triggered successfully!`);
      Mixpanel.track("Triggered Action", { action: actionType });
    } catch (error) {
      console.error(error);
      beamtoast.error(`Error in triggering action: ${actionType}`);
      Mixpanel.track("Failure", {
        type: `trigger action ${actionType}`,
        error: JSON.stringify(error),
      });
    } finally {
      this.setActionButtonsDisabled(false);
      this.setState({
        autoRetry: false,
        releaseNotes: "",
        endTimestamp: neverTimestamp,
      });
    }
  };

  openUpdateFirmwareModal = () => {
    this.setState({
      updateFirmwareModalIsOpen: true,
    });
  };

  closeUpdateFirmwareModal = () => {
    this.setState({
      updateFirmwareModalIsOpen: false,
    });
  };

  openConfirmationModal = (actionType) => {
    this.setState({
      openConfirmationModal: true,
      eventType: actionType,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      openConfirmationModal: false,
    });
  };

  openUpdateConfigModal = () => {
    this.setState({
      updateConfigModalIsOpen: true,
    });
  };

  closeUpdateConfigModal = () => {
    this.setState({
      updateConfigModalIsOpen: false,
    });
  };

  openUpdateGeofenceModal = () => {
    this.setState({
      updateGeofenceModalIsOpen: true,
    });
  };

  closeUpdateGeofenceModal = () => {
    this.setState({
      updateGeofenceModalIsOpen: false,
    });
  };

  openUploadScriptModal = () => {
    this.setState({
      updateScriptModalIsOpen: true,
    });
  };

  closeUploadScriptModal = () => {
    this.setState({
      updateScriptModalIsOpen: false,
    });
  };

  openSendFileModal = () => {
    this.setState({
      sendFileModalIsOpen: true,
    });
  };

  closeSendFileModal = () => {
    this.setState({
      sendFileModalIsOpen: false,
    });
  };

  openBulkMetadataOperationsModal = () => {
    this.setState({
      bulkMetadataOperationsModalIsOpen: true,
    });
  };

  closeBulkMetadataOperationsModal = () => {
    this.setState({
      bulkMetadataOperationsModalIsOpen: false,
    });
  };

  updateTimeout = (timeout: Date | number) => {
    this.setState({
      endTimestamp: timeout,
    });
  };

  updateAutoRetry = (retryUntil: number | Date) => {
    this.setState({
      autoRetry: Boolean(retryUntil),
    });
  };

  updateReleaseNotes = (notes: string) => {
    this.setState({
      releaseNotes: notes,
    });
  };

  setDevicePageToLoadingState = () => this.setState({ loading: true });

  setFiltersTypeMismatchToastId = (id: string | null) => {
    this.setState({ filtersTypeMismatchToastId: id });
  };

  handlePaginationInputChange = (event) => {
    const newValue = event.target.value;
    this.setState({
      inputPageNumber: newValue,
    });
  };

  handlePaginationInputKeyDown = (event) => {
    const { inputPageNumber, pageCount } = this.state;

    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page

      if (validateWholeNumber(inputPageNumber.toString())) {
        let activePage = 1; // Default to the first page if the input is invalid or out of bounds
        if (inputPageNumber > 0) {
          activePage = Math.min(inputPageNumber, pageCount);
        }

        this.handlePaginationChange(event, { activePage });
        this.setState({
          inputPageNumber: 0,
        });
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  handlePaginationChange = async (event, data) => {
    this.setState({ activePage: data.activePage, loading: true });
    await this.refreshDevices();
  };

  setRemoteShellDeviceId = (deviceId: number) => {
    this.setState({ remoteShellDeviceId: deviceId });
  };

  /**
   * Retrieves the filters for devices based on the current state and metadata filters.
   * @returns {searchDevicesFilters} The filters for devices.
   */
  getDevicesFilters = (): searchDevicesFilters => {
    const { devicesFilterQuery } = this.state;
    let filters: searchDevicesFilters = {};

    if (
      Array.isArray(devicesFilterQuery?.stateFilters) &&
      devicesFilterQuery.stateFilters.length > 0
    ) {
      filters.stateFilters = devicesFilterQuery.stateFilters;
    }

    if (Object.keys(devicesFilterQuery?.metaDatafilters ?? {}).length > 0) {
      filters.metaDatafilters = devicesFilterQuery.metaDatafilters;
    }

    return filters;
  };

  async refreshDevices() {
    const { showInactiveDevice, activePage, devicesPerPage } = this.state;
    let devices: Device[] | Promise<Device[]> = [];
    let status = showInactiveDevice ? "all" : "active";
    let pageCount = 0;
    let devicesCount = 0;

    this.abortController.abort();
    this.abortController = new AbortController();
    try {
      if (this.state.searchModeOn) {
        const filters = this.getDevicesFilters();
        // Ensure filters object has at least one defined property before calling searchDevices
        if (filters.stateFilters || filters.metaDatafilters) {
          try {
            const res = await searchDevices(
              filters as AtLeastOnePropRequired<searchDevicesFilters>, // Ensure type safety
              activePage,
              devicesPerPage,
              status,
              this.abortController.signal
            );
            this.setState({ streamTypesMismatchError: false });
            devices = res.devices;
            devicesCount = res.count;
            pageCount = Math.ceil(res.count / this.state.devicesPerPage);
          } catch (e) {
            /**  This is workaround for the issue where the API returns 500 when the filters are of invalid types,
             * This will probably be removed when we do the types validation for input and operators based on streams.
             * eg: mode: 1, instead of mode: "1" or mode: "on" */
            if (e instanceof DOMException) {
              // Request was cancelled by abort controller
              return;
            }
            this.setState({
              errorOccurred: true,
              loading: false,
              streamTypesMismatchError: true,
            });
          }
        }
      } else {
        const res: SearchDeviceResponse = await fetchAllDevicesWithCountV2(
          activePage,
          devicesPerPage,
          status,
          this.abortController.signal
        );
        devices = res.devices;
        devicesCount = res.count;
        pageCount = Math.ceil(res.count / this.state.devicesPerPage);
      }

      this.setState({
        devices: devices,
        pageCount,
        devicesCount: devicesCount,
      });
    } catch (e) {
      if (e instanceof DOMException) {
        // Request was cancelled by abort controller
        return;
      }
      this.setState({ errorOccurred: true });
      return;
    }

    // Set the Stream types mismatch error state to false if the search mode is off
    if (!this.state.searchModeOn) {
      this.setState({ streamTypesMismatchError: false });
    }

    this.setState({
      loading: false,
      lastRefreshTime: moment(),
    });
  }

  setShowInactiveDevice = (value: boolean) => {
    this.setState({
      showInactiveDevice: value,
    });
  };

  handleToggleClick = async () => {
    this.setShowInactiveDevice(!this.state.showInactiveDevice);
    this.setState({
      loading: true,
      activePage: 1,
      selectedDevices: new Map<string | number, string>(),
      selectedDeviceCount: 0,
      allSelected: false,
    });
    await this.refreshDevices();
  };

  downloadFile = async (data, key) => {
    const element = document.createElement("a");
    const file = new Blob([data], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = key;
    document.body.appendChild(element);

    setTimeout(() => element.click());
  };

  downloadCertificates = async (id: number) => {
    const res = await downloadCertificates(id);
    await this.downloadFile(JSON.stringify(res, null, 2), `device_${id}.json`);
  };

  changeDeviceStatus = async (id: number, status: string) => {
    try {
      this.setState({ loading: true });
      await changeDeviceStatus(id, status);

      // Check if the device id exists in the selectedDevices map, if it does, update the status with the new status.
      if (this.state.selectedDevices.has(id)) {
        this.selectDevice(id, status);
      }

      await this.refreshDevices();
      beamtoast.success(`Device status set to ${status}`);
    } catch (e) {
      beamtoast.error("Failed to change device status");
      console.error("Error in changeDeviceStatus: ", e);
    }
  };

  changeDevicesPerPage = async (e, data) => {
    try {
      this.setState({
        loading: true,
        devicesPerPage: data.value,
        activePage: 1,
      });
      window.localStorage.setItem("devicesPerPage", data.value);
      await this.refreshDevices();
    } catch (e) {
      beamtoast.error("Failed to change number of devices per page");
      console.error("Error in changeDeviceStatus: ", e);
    }
  };

  setActiveIndex = (value: number) => {
    this.setState({ activeIndex: value });
  };

  filterSearchTimeout: any = null;

  // Function to search devices based on the search query
  searchDeviceWithFilters = (deviceSearchQuery: searchDevicesFilters) => {
    let searchModeUpdate = {};

    if (
      (deviceSearchQuery?.stateFilters?.length ?? 0) > 0 ||
      Object.keys(deviceSearchQuery?.metaDatafilters ?? {}).length > 0
    ) {
      searchModeUpdate = {
        searchModeOn: true,
        activePage: 1,
      };
    } else {
      searchModeUpdate = { searchModeOn: false, activePage: 1 };
    }

    // Clear the existing timeout
    clearTimeout(this.filterSearchTimeout);
    // Set a new timeout
    this.filterSearchTimeout = setTimeout(async () => {
      this.setState({
        ...searchModeUpdate,
        devicesFilterQuery: deviceSearchQuery,
        loading: true,
      });
      await this.refreshDevices();
    }, 700);
  };

  // Functions used to set the state of the stateKeys and metadataKeys when the user value changes
  usingUpdatedUserPermissions = async () => {
    const permissions: Permission = this.props.user?.role?.permissions;

    // Choosing not to show sequence in UI table, even though data is available via API.
    const stateKeys: string[] = (
      (permissions.tables["device_shadow"] as string[]) || ([] as string[])
    ).filter((key: string) => key !== "sequence");
    const metadataKeys: string[] =
      (permissions.viewMetadata as string[]) || ([] as string[]);

    this.setState({
      stateKeys,
      metadataKeys,
    });
  };

  async getDashboardList() {
    try {
      const res = await fetchAllDashboards();
      this.setState({
        dashboards: res,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getStreamList() {
    try {
      const res = filterTableInfo(await fetchTableInfo());
      this.setState({
        streamsList: res,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getDetailedStreamList() {
    try {
      const res = await fetchAllStreamsWithDetails();
      this.setState({
        detailedStreamsList: res,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getActionTypes() {
    try {
      const res = await fetchAllActionTypes();
      this.setState({
        actionTypes: res,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async runInitialSetup() {
    const { activePage, devicesPerPage } = this.state;
    try {
      const res: SearchDeviceResponse = await fetchAllDevicesWithCountV2(
        activePage,
        devicesPerPage,
        "active"
      );
      const pageCount = Math.ceil(res.count / devicesPerPage);

      await this.getActionTypes();
      await this.getDashboardList();
      await this.getStreamList();
      await this.getDetailedStreamList();

      this.setState({
        devices: res.devices,
        loading: false,
        lastRefreshTime: moment(),
        pageCount,
        devicesCount: res.count,
      });
    } catch (error) {
      console.error("Error during initial setup:", error);
    }
  }

  async startDeviceRefreshLoop() {
    try {
      await this.refreshDevices();
      if (this.isComponentMounted) {
        this.timeoutId = setTimeout(() => {
          this.startDeviceRefreshLoop();
        }, 1000);
      }
    } catch (error) {
      console.error("Error during device refresh:", error);
    }
  }

  async loadSettingsFromLocalStorage() {
    const devicesCountPerPage = parseInt(
      window.localStorage.getItem("devicesPerPage") ?? "10"
    );
    this.setState({
      devicesPerPage: devicesCountPerPage,
    });
  }

  async componentDidMount() {
    // Scroll to top and set document title
    window.scrollTo(0, 0);
    document.title = "Devices | Bytebeam";

    // Update user permissions
    await this.usingUpdatedUserPermissions();

    // Load settings from local storage
    await this.loadSettingsFromLocalStorage();

    // Run initial setup
    await this.runInitialSetup();

    // Start device refresh loop
    await this.startDeviceRefreshLoop();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      // setting the state of the stateKeys and metadataKeys on user change
      this.usingUpdatedUserPermissions();
    }

    const invalidSearchFilterTypes =
      this.state.searchModeOn && this.state.streamTypesMismatchError === true;

    // Show a toast if the search filters are of invalid types and search mode is on
    handleConditionalDismissibleToast(
      invalidSearchFilterTypes,
      this.state.filtersTypeMismatchToastId,
      (id) => this.setFiltersTypeMismatchToastId(id),
      "Filters are of invalid types",
      { duration: 50000 }
    );
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    this.onSelectedDeviceCountChange(0);
    this.abortController.abort();
    clearTimeout(this.timeoutId);
  }

  renderDeviceTable() {
    const permissions: Permission = this.props.user?.role?.permissions;
    const serial_metadata =
      this.props.user?.["tenant-settings"]?.["serial-key"];
    const tenant_settings = this.props.user["tenant-settings"] ?? {
      common_settings: {
        pin_metadata: [],
      },
      "serial-key": null,
      dashboard_settings: {
        custom_time_ranges: {},
      },
      hardware_type: HARDWARE_TYPES[0],
      show_tabs: null,
      logo: {
        light: "",
        dark: "",
      },
      favicon: {
        light: "",
        dark: "",
      },
    };
    const common_settings = tenant_settings?.common_settings ?? {
      pin_metadata: [],
    };
    const pinnedMetadataKeys = common_settings?.pin_metadata ?? [];
    const { stateKeys, metadataKeys, allSelected, selectedDevices } =
      this.state;
    const allowedActions = permissions.allowedActions || [];
    const editableMetadataKeys: Set<string> = new Set(
      permissions.editMetadata || []
    );

    function getColumnWidth(cellType: string): SemanticWIDTHS {
      const hasLastHeartbeat = Object.keys(permissions?.tables)?.length > 0;
      const hasAllowedActions = allowedActions?.length > 0;
      const pinnedKeyCount = pinnedMetadataKeys?.length ?? 0;

      // Switch based on the type of cell
      switch (cellType) {
        // Actions column width should be 1
        case "action_checkbox":
          return 1;

        case "id":
          if (hasAllowedActions) {
            // If there are allowed actions, width depends on pinned key count
            return pinnedKeyCount > 0 ? 1 : 2;
          } else {
            // If no allowed actions, width depends on pinned key count and last heartbeat
            if (pinnedKeyCount === 3) return 2;
            return pinnedKeyCount > 0 ? (hasLastHeartbeat ? 2 : 3) : 3;
          }

        case "-serial_metadata":
          if (hasAllowedActions) {
            // If there are allowed actions, width depends on pinned key count
            return pinnedKeyCount > 0 ? 2 : 3;
          } else {
            // If no allowed actions, width depends on pinned key count and last heartbeat
            if (pinnedKeyCount > 2) return 2;
            return pinnedKeyCount === 0 && !hasLastHeartbeat ? 4 : 3;
          }

        case "last_heartbeat":
          if (hasAllowedActions) {
            // If there are allowed actions, width depends on pinned key count
            return pinnedKeyCount === 3 ? 2 : 3;
          } else {
            // If no allowed actions, width depends on pinned key count and last heartbeat
            return pinnedKeyCount >= 2 ? 2 : 3;
          }

        case "status":
          if (!hasAllowedActions) {
            // If no allowed actions, width depends on pinned key count and last heartbeat
            if (pinnedKeyCount === 0) return hasLastHeartbeat ? 3 : 4;
            return pinnedKeyCount === 1 ? (hasLastHeartbeat ? 2 : 3) : 2;
          } else {
            // If there are allowed actions, width depends on pinned key count
            return pinnedKeyCount >= 2 ? 1 : pinnedKeyCount > 0 ? 2 : 3;
          }

        // Pinned metadata keys column width is always 2
        case "pinned_metadata":
          return 2;

        case "action_type":
          if (serial_metadata) {
            // If serial metadata is enabled, width logic based on pinned key count and last heartbeat
            if (pinnedKeyCount === 3) return hasLastHeartbeat ? 1 : 2;
            // If there are no pinned metadata keys
            if (pinnedKeyCount === 0) return hasAllowedActions ? 2 : 3;
            return pinnedKeyCount <= 2 ? (hasLastHeartbeat ? 2 : 3) : 3;
          } else {
            // If serial metadata is not enabled, width logic based on pinned key count and last heartbeat
            if (pinnedKeyCount === 0 && !hasAllowedActions)
              return hasLastHeartbeat ? 3 : 4;
            if (pinnedKeyCount === 3)
              return hasAllowedActions ? 2 : hasLastHeartbeat ? 1 : 2;
            // For 1 or 2 pinned metadata keys, or other cases
            return 3;
          }

        // Last Action column width logic
        case "last_action":
          if (pinnedKeyCount >= 2) {
            // If pinned key count is 2 or more, width logic based on last heartbeat
            return hasLastHeartbeat ? 2 : 3;
          }
          if (!hasAllowedActions) {
            // If no allowed actions, width logic based on pinned key count and last heartbeat
            return pinnedKeyCount === 0 || pinnedKeyCount === 1
              ? hasLastHeartbeat
                ? 3
                : 4
              : 3;
          }
          return 3;

        default:
          return 1;
      }
    }

    return (
      <>
        <StyledHeaderContainer fluid>
          <Grid>
            <Grid.Row>
              {allowedActions?.length > 0 ? (
                <StyledGridColumn
                  style={{ position: "relative", justifyContent: "flex-start" }}
                >
                  <Checkbox
                    width={getColumnWidth("action_checkbox")}
                    checked={this.state.allSelected}
                    // Select all devices checkbox should be disabled if there are no devices to restrict enabling Actions
                    // or if filters are applied with stateFilters as they aren't constant.
                    disabled={
                      this.state.devices?.length === 0 ||
                      (Array.isArray(
                        this.state.devicesFilterQuery?.stateFilters
                      ) &&
                        this.state.devicesFilterQuery.stateFilters.length > 0)
                    }
                    onChange={(e, { checked }) => {
                      if (checked) this.selectAll();
                      else this.clearAll();
                    }}
                  />
                  {this.state.selectedDeviceCount ? (
                    <Popup
                      content={"Unselect the devices"}
                      position="top center"
                      inverted
                      trigger={
                        <DeviceCountLabel
                          id="deviceCount_label"
                          onClick={this.clearAll}
                        >
                          {this.state.selectedDeviceCount}
                        </DeviceCountLabel>
                      }
                    />
                  ) : null}
                </StyledGridColumn>
              ) : null}
              <StyledGridColumn
                style={{ fontWeight: 600 }}
                width={
                  this.props.user?.["tenant-settings"]?.["serial-key"]
                    ? getColumnWidth("-serial_metadata")
                    : getColumnWidth("id")
                }
              >
                {this.props.user?.["tenant-settings"]?.["serial-key"]
                  ? `#${capitalizeFirstLetter(this.props.user?.["tenant-settings"]?.["serial-key"])}`
                  : `#Id`}
              </StyledGridColumn>
              {Object.keys(permissions?.tables)?.length > 0 && (
                <StyledGridColumn
                  style={{ fontWeight: 600 }}
                  width={getColumnWidth("last_heartbeat")}
                >
                  Last Heartbeat
                </StyledGridColumn>
              )}
              <StyledGridColumn
                style={{ fontWeight: 600 }}
                width={getColumnWidth("status")}
              >
                Status
              </StyledGridColumn>

              {pinnedMetadataKeys?.length !== 0 &&
                pinnedMetadataKeys?.map((key) => (
                  <StyledGridColumn
                    style={{ fontWeight: 600 }}
                    width={getColumnWidth("pinned_metadata")}
                    key={key}
                  >
                    {capitalizeFirstLetter(key)}
                  </StyledGridColumn>
                ))}

              <StyledGridColumn
                style={{ fontWeight: 600 }}
                width={getColumnWidth("action_type")}
              >
                Last Action Type
              </StyledGridColumn>

              <StyledGridColumn
                style={{ fontWeight: 600 }}
                width={getColumnWidth("last_action")}
              >
                Last Action Progress
              </StyledGridColumn>
            </Grid.Row>
          </Grid>
        </StyledHeaderContainer>

        <ThinDivider />

        {this.state.devices?.length !== 0 ? (
          <StyledBodyContainer fluid>
            <Accordion>
              {this.state.devices?.map((device) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  dashboards={this.state.dashboards}
                  streamsList={this.state.streamsList}
                  allowedActions={allowedActions}
                  allSelected={allSelected}
                  selectedDevices={selectedDevices}
                  selectDevice={this.selectDevice}
                  clearDevice={this.clearDevice}
                  stateKeys={stateKeys}
                  metadataKeys={metadataKeys}
                  editableMetadataKeys={editableMetadataKeys}
                  downloadCertificates={this.downloadCertificates}
                  changeDeviceStatus={this.changeDeviceStatus}
                  setRemoteShellDeviceId={this.setRemoteShellDeviceId}
                  permissions={permissions}
                  activeIndex={this.state.activeIndex}
                  setActiveIndex={this.setActiveIndex}
                  tenant_settings={tenant_settings}
                  getColumnWidth={getColumnWidth}
                  setDevicePageToLoadingState={this.setDevicePageToLoadingState}
                  detailedStreamsList={this.state.detailedStreamsList}
                  actionTypes={this.state.actionTypes}
                />
              ))}
            </Accordion>
          </StyledBodyContainer>
        ) : (
          <div
            style={{
              height: "55vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorMessage
              marginTop="30px"
              message={
                "No devices found! Please check your filters or add a new device."
              }
            />
          </div>
        )}
      </>
    );
  }

  render() {
    const permissions: Permission = this.props.user?.role?.permissions;
    const theme = this.props.user?.settings?.theme ?? "dark";
    const allowedActions = permissions.allowedActions || [];

    return (
      <Column>
        <BulkMetadataOperationsModal
          isOpen={this.state.bulkMetadataOperationsModalIsOpen}
          close={this.closeBulkMetadataOperationsModal}
          getDevicesFilters={this.getDevicesFilters}
          setLoading={() => this.setState({ loading: true })}
          selectedDevices={Array.from(this.state.selectedDevices.keys()).map(
            (id) => String(id)
          )}
          theme={theme}
        />

        {/* ======================================== Action Modals ======================================== */}
        <UpdateFirmwareModal
          isOpen={this.state.updateFirmwareModalIsOpen}
          close={this.closeUpdateFirmwareModal}
          triggerAction={this.triggerAction}
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
          endTimestamp={this.state.endTimestamp}
          releaseNotes={this.state.releaseNotes}
          updateActionTimeout={this.updateTimeout}
          updateAutoRetry={this.updateAutoRetry}
          updateReleaseNotes={this.updateReleaseNotes}
        />

        <UpdateConfigModal
          isOpen={this.state.updateConfigModalIsOpen}
          close={this.closeUpdateConfigModal}
          triggerAction={this.triggerAction}
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
          endTimestamp={this.state.endTimestamp}
          updateActionTimeout={this.updateTimeout}
          updateAutoRetry={this.updateAutoRetry}
          action_type="update_config"
        />

        <UpdateConfigModal
          isOpen={this.state.updateGeofenceModalIsOpen}
          close={this.closeUpdateGeofenceModal}
          triggerAction={this.triggerAction}
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
          endTimestamp={this.state.endTimestamp}
          updateActionTimeout={this.updateTimeout}
          updateAutoRetry={this.updateAutoRetry}
          action_type="update_geofence"
        />

        <SendFileModal
          isOpen={this.state.sendFileModalIsOpen}
          close={this.closeSendFileModal}
          triggerAction={this.triggerAction}
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
          endTimestamp={this.state.endTimestamp}
          updateActionTimeout={this.updateTimeout}
          updateAutoRetry={this.updateAutoRetry}
        />

        <SendScriptModal
          isOpen={this.state.updateScriptModalIsOpen}
          close={this.closeUploadScriptModal}
          triggerAction={this.triggerAction}
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
          endTimestamp={this.state.endTimestamp}
          updateActionTimeout={this.updateTimeout}
          updateAutoRetry={this.updateAutoRetry}
        />

        <ActionConfirmationModal
          actionType={this.state.eventType}
          isOpen={this.state.openConfirmationModal}
          close={this.closeConfirmationModal}
          triggerAction={this.triggerAction}
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
          endTimestamp={this.state.endTimestamp}
          updateActionTimeout={this.updateTimeout}
          updateAutoRetry={this.updateAutoRetry}
          theme={theme}
        />

        <RemoteShellModal
          deviceId={this.state.remoteShellDeviceId}
          isOpen={this.state.remoteShellDeviceId > 0}
          close={() => this.setState({ remoteShellDeviceId: -1 })}
        />

        {/* ======================================== Action Modals Ends ======================================== */}

        <ActionBar>
          <ActionButtons
            allSelected={this.state.allSelected}
            selectedDevices={this.state.selectedDevices}
            openUploadScriptModal={this.openUploadScriptModal}
            openConfirmationModal={this.openConfirmationModal}
            openUpdateFirmwareModal={this.openUpdateFirmwareModal}
            openUpdateConfigModal={this.openUpdateConfigModal}
            openSendFileModal={this.openSendFileModal}
            openUpdateGeofenceModal={this.openUpdateGeofenceModal}
            allowedActions={allowedActions}
            actionButtonsDisabled={this.state.actionButtonsDisabled}
            actionTypes={this.state.actionTypes}
          />

          <ActionBarRight>
            <ActionButtonV3
              onClick={() => this.setShowFilterInputs()}
              border_style={"dashed"}
              label={"Filters"}
              icon={"filter"}
              margin_left="0px"
            />
            <Popup
              content="Download/Update Metadata in bulk"
              position="top center"
              inverted
              trigger={
                <ActionButton
                  onClick={this.openBulkMetadataOperationsModal}
                  // Metadata button should be disabled when No devices are present
                  disabled={
                    this.state.loading || this.state.devices?.length === 0
                  }
                  style={{
                    background: "transparent",
                    marginRight: "0px",
                    color: theme === "light" ? "#17191d" : "#C1C1C1",
                    border: `1px solid ${
                      theme === "light" ? "#17191d" : "#C1C1C1"
                    }`,
                    padding: "9px 16px",
                    borderRadius: "4px",
                  }}
                >
                  <StyledSVGIcon
                    height={"15px"}
                    svgContent={`${UpDownArrows}`}
                  />
                  <span>Metadata</span>
                </ActionButton>
              }
            />

            <DisplayIf cond={permissions.allowCreatingDevices}>
              <DeviceProvisionModal
                onConfirm={async () => {
                  this.setState({ loading: true });
                  await this.refreshDevices();
                }}
                keys={this.state.metadataKeys}
              />
            </DisplayIf>
          </ActionBarRight>
        </ActionBar>
        <DevicesFilterUI
          user={this.props.user}
          stateKeys={this.state.stateKeys}
          metadataKeys={this.state.metadataKeys}
          showFilterInputs={this.state.showFilterInputs}
          onSearch={this.searchDeviceWithFilters}
        />

        {this.state.loading ? (
          <LoadingAnimation
            loaderContainerHeight="65vh"
            fontSize="1.5rem"
            loadingText="Loading devices"
          />
        ) : (
          this.renderDeviceTable()
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {this.state.pageCount > 0 &&
          !this.state.loading &&
          this.state.devices.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "nowrap",
                gap: "16px",
              }}
            >
              <Pagination
                boundaryRange={0}
                defaultActivePage={this.state.activePage}
                ellipsisItem={null}
                siblingRange={2}
                totalPages={this.state.pageCount}
                onPageChange={this.handlePaginationChange}
              />

              <SearchPageInput
                icon="search"
                placeholder="Jump to page..."
                name="activePage"
                min={1}
                onChange={this.handlePaginationInputChange}
                onKeyDown={this.handlePaginationInputKeyDown}
                type="number"
                value={
                  this.state.inputPageNumber ? this.state.inputPageNumber : ""
                }
              />

              <StyledDevicePerPageWidget>
                <MenuItem>Devices per page</MenuItem>
                <MenuItem style={{ padding: "0px" }}>
                  <SelectDevicesPerPage
                    compact
                    selection
                    options={devicesPerPageOptions}
                    value={this.state.devicesPerPage}
                    onChange={this.changeDevicesPerPage}
                  />
                </MenuItem>
              </StyledDevicePerPageWidget>
            </div>
          ) : (
            <div></div>
          )}
          {this.state.showInactiveDeviceToggle &&
          !this.state.loading &&
          this.state.devices.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexWrap: "nowrap",
                gap: "16px",
              }}
            >
              <div style={{ marginLeft: "auto" }}>Show Inactive Devices</div>
              <Toggle
                id="showInactiveDeviceToggle"
                checked={this.state.showInactiveDevice}
                onClick={() => {
                  this.handleToggleClick();
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div>
          {/* Show only when, once it has refreshed */}
          {this.state.lastRefreshTime ? (
            <div className="last-refresh-time">
              <Icon
                name="info"
                style={{
                  marginRight: "7px",
                }}
              />
              Last refreshed:{" "}
              {moment(this.state.lastRefreshTime).format("HH:mm:ss")}
            </div>
          ) : (
            <></>
          )}
        </div>
      </Column>
    );
  }
}
