import React, { useEffect, useState } from "react";
import { UserSettings } from "../../../util";
import NewProjectModal from "../../Screens/NewProjectModal";
import DesktopNavBar from "./DesktopNavBar";
import { updateUserSettings } from "../../../BytebeamClient";
import MobileNavBar from "./MobileNavBar";
import { useUser } from "../../../context/User.context";
import { breakpoints } from "../breakpoints";

type MainNavbarProps = {
  currentTenant: string;
  isSingleTenant: boolean;
  isMultiTenant: boolean;
  setCurrentTenant: (tenant: string) => void;
};

function MainNavbar(props: MainNavbarProps) {
  const { currentTenant, isMultiTenant, isSingleTenant, setCurrentTenant } =
    props;

  const [activeItem, setActiveItem] = useState<string>(
    getActiveItem(window.location.pathname)
  );
  const [showNewProjectModal, setShowNewProjectModal] =
    useState<boolean>(false);
  const { user, getCurrentUser } = useUser();
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  // const permissions = user?.role?.permissions ?? {};

  function getActiveItem(path: string) {
    let pathParts = path?.split("/");
    let activeItem = "";

    if (pathParts?.includes("device-management")) {
      activeItem = "device-management";
    } else if (pathParts?.includes("dashboards")) {
      activeItem = "dashboards";
    } else if (pathParts?.includes("actions")) {
      activeItem = "actions";
    } else if (pathParts?.includes("actions-old")) {
      activeItem = "actions";
    } else if (pathParts?.includes("get-started")) {
      activeItem = "get-started";
    } else if (pathParts?.includes("settings")) {
      activeItem = "settings";
    } else if (pathParts?.includes("alerts")) {
      activeItem = "alerts";
    }
    return activeItem;
  }

  useEffect(() => {
    setActiveItem(getActiveItem(window.location.pathname));
  }, [window.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  function showSettingsTab() {
    return (
      // permissions.viewRoles ||
      // permissions.viewUsers ||
      // permissions.viewActionTypes ||
      // permissions.viewMetadataKeys ||
      // (permissions.viewStreams && window.location.pathname !== "/welcome")
      (user.role.name === "admin" || user.role.name === "demorole") &&
      window.location.pathname !== "/welcome"
    );
  }

  // Function for handling Theme Toggle
  async function handleThemeToggle() {
    const userSettings = user?.settings ?? ({} as UserSettings);
    const theme = userSettings?.theme === "light" ? "dark" : "light";
    try {
      window.localStorage.setItem("defaultTheme", theme);

      const res = await updateUserSettings({
        settings: {
          ...userSettings,
          theme: theme,
        },
      });

      if (res !== undefined && res !== null) {
        await getCurrentUser();
        window.location.reload();
      }
    } catch (error) {
      console.log("Error while updating user settings", error);
    }
  }

  useEffect(() => {
    const updateDimensions = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <>
      {!isSingleTenant && (
        <NewProjectModal
          isOpen={showNewProjectModal}
          setCurrentTenant={setCurrentTenant}
          onClose={() => setShowNewProjectModal(false)}
        />
      )}
      {screenWidth > breakpoints.sm ? (
        <DesktopNavBar
          user={user}
          getCurrentUser={getCurrentUser}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          currentTenant={currentTenant}
          setCurrentTenant={setCurrentTenant}
          isMultiTenant={isMultiTenant}
          showSettingsTab={showSettingsTab}
          setShowNewProjectModal={setShowNewProjectModal}
          handleThemeToggle={handleThemeToggle}
        />
      ) : (
        <MobileNavBar
          user={user}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          currentTenant={currentTenant}
          showSettingsTab={showSettingsTab}
          isMultiTenant={isMultiTenant}
          setCurrentTenant={setCurrentTenant}
          setShowNewProjectModal={setShowNewProjectModal}
          handleThemeToggle={handleThemeToggle}
        />
      )}
    </>
  );
}

export default MainNavbar;
